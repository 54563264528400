export const DataTypeUtils = {
	areDifferent<T>(a: T, b: T): boolean {
		if (this.isObject(a) && this.isObject(b)) {
			return Object.keys(this.getObjectDifferences(a, b)).length > 0;
		}
		if (Array.isArray(a) && Array.isArray(b)) {
			return a.length !== b.length || a.some((item, index) => this.areDifferent(item, b[index]));
		}
		if (this.isLiteralType(a) && this.isLiteralType(b)) {
			return a !== b;
		}

		return false;
	},

	/**
	 * Difference between two objects.
	 * @param firstObject - Object 1.
	 * @param secondObject - Object 2.
	 *
	 * @description This method is used to compare two objects and return the difference between them.
	 */
	getObjectDifferences<T extends Record<any, any>, U extends Record<any, any>>(
		firstObject: T,
		secondObject: U = {} as U,
	): Partial<T & U> {
		const allKeys = new Set([...Object.keys(firstObject), ...Object.keys(secondObject)]);
		return [...allKeys].reduce((result, key) => {
			const valueAtFirstObjectKey = firstObject[key];
			const valueAtSecondObjectKey = secondObject[key];
			if (Array.isArray(valueAtFirstObjectKey) && Array.isArray(valueAtSecondObjectKey)) {
				result[key] = valueAtSecondObjectKey.filter((item) => !valueAtFirstObjectKey.includes(item));
			} else if (this.isObject(valueAtFirstObjectKey) && this.isObject(valueAtSecondObjectKey)) {
				result[key] = this.getObjectDifferences(valueAtFirstObjectKey, valueAtSecondObjectKey);
				if (Object.keys(result[key]).length === 0) {
					delete result[key];
				}
			} else if (valueAtSecondObjectKey !== valueAtFirstObjectKey) {
				result[key] = valueAtSecondObjectKey;
			}
			return result;
		}, {} as any);
	},

	isLiteralType(value: unknown): value is boolean | number | string {
		return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
	},

	isNullOrUndefined<T>(value: T | null | undefined): value is null | undefined {
		return value === undefined || value === null;
	},

	isObject<T>(value: T): value is T & Record<any, any> {
		return typeof value === 'object' && value !== null;
	},
};
